import {memo} from 'react';
import {useLoaderData} from '@remix-run/react';

import AnimatedImage from '@/pages/shopify.com/($locale)/free-trial/components/AnimatedImage';
import Image from '@/components/base/elements/Image/Image';
import {useTranslations} from '@/components/shared/Page/I18N';
import type {loader} from '@/pages/shopify.com/($locale)/free-trial/server/loader.server';

export const DynamicHeroImage = memo(function DynamicHeroImage() {
  const {t, site} = useTranslations();
  const {settings} = useLoaderData<typeof loader>();

  const sitePrefix: string = site.pathPrefix!;
  const images = settings.images;
  const lotties = settings.lotties;
  const hasAnimatedHero =
    [
      'au',
      'br',
      'ca',
      'ca-fr',
      'de',
      'es',
      'es-es',
      'hk',
      'hk-en',
      'id',
      'ie',
      'in',
      'it',
      'mx',
      'my',
      'sg',
      'tw',
      'uk',
      'za',
      'zh',
    ].includes(sitePrefix) || sitePrefix === undefined;

  return hasAnimatedHero ? (
    <>
      <AnimatedImage
        lottieData={lotties.storefrontAnimated.mobile}
        className="max-w-[464px] mx-auto w-3/4 md:w-1/3 md:hidden aspect-square -translate-y-5"
        alt={t('animations.storefrontAnimated.alt')}
      />
      <AnimatedImage
        lottieData={lotties.storefrontAnimated.desktop}
        className="max-w-[464px] mx-auto w-3/4 md:w-1/3 hidden md:block aspect-square -translate-y-7"
        alt={t('animations.storefrontAnimated.alt')}
      />
    </>
  ) : (
    <Image
      className="max-w-[464px] mx-auto w-3/4 md:w-1/3"
      alt={t('image.image.alt')}
      src={images.image.en}
      srcSet={images.image.en}
    />
  );
});
